import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemText, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";

export function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const navStyles = {
    '&:hover': {
      cursor: 'pointer',
    },
  };

  return (
    <Box>
      <AppBar position="static" sx={{ px: isMobile ? 2 : 20, backgroundColor: "#eeff41ff", color: "black" }}>
        <Toolbar>
          <Typography variant="h4" sx={{ flexGrow: 1, py: 2 }} fontFamily="monospace">
            abhiyant.ai
          </Typography>
          {isMobile ? (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <List component="nav" aria-labelledby="main navigation" sx={{ display: 'flex', flexDirection: 'row' }}>
              <ListItem key="home" sx={navStyles}>
                <ListItemText primary="Home" onClick={() => scrollToSection('home')} />
              </ListItem>
              <ListItem key="work" sx={navStyles}>
                <ListItemText primary="Work" onClick={() => scrollToSection('work')} />
              </ListItem>
              <ListItem key="goals" sx={navStyles}>
                <ListItemText primary="Goals" onClick={() => scrollToSection('goals')} />
              </ListItem>
              <ListItem key="ideas" sx={navStyles}>
                <ListItemText primary="Ideas" onClick={() => scrollToSection('ideas')} />
              </ListItem>
              <ListItem key="contact" sx={navStyles}>
                <ListItemText primary="Contact" onClick={() => scrollToSection('contact')} />
              </ListItem>
            </List>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={openDrawer}
        onClose={toggleDrawer}
      >
        <Box
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <List>
            <ListItem key="home" sx={navStyles}>
              <ListItemText primary="Home" onClick={() => scrollToSection('home')} />
            </ListItem>
            <ListItem key="work" sx={navStyles}>
              <ListItemText primary="Work" onClick={() => scrollToSection('work')} />
            </ListItem>
            <ListItem key="goals" sx={navStyles}>
              <ListItemText primary="Goals" onClick={() => scrollToSection('goals')} />
            </ListItem>
            <ListItem key="ideas" sx={navStyles}>
              <ListItemText primary="Ideas" onClick={() => scrollToSection('ideas')} />
            </ListItem>
            <ListItem key="contact" sx={navStyles}>
              <ListItemText primary="Contact" onClick={() => scrollToSection('contact')} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>);
}