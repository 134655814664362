import React from 'react';
import './App.css';
import { Box, Container, List, ListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Navbar } from "./navbar";
import LLMImage from "./assets/llm.png"
import CInLoopImage from "./assets/CinLoop.png"

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Box display="flex" minHeight="100vh" flexDirection="column" id="home">
        <Navbar />
        <Container sx={{ py: 20, fontFamily: "monospace", flexGrow: 1 }}>
          <Box display="flex" flexDirection="column" gap={10} alignItems="center">
            <Typography variant="h2" align="center" fontWeight="bold">
              The catalyst for your AI journey
            </Typography>

            <Typography fontSize={36} align="center">
              When Everybody is Digging for Gold,<br />
              It’s Good To Be in the Pick and Shovel Business
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box display="flex" minHeight="100vh" flexDirection="column" id="work">
        <Box display="flex" flexDirection="column" gap={5} alignItems="left" sx={{ mx: isMobile ? 2 : 18 }}>
          <Box sx={{ backgroundColor: "#eeff41ff", borderRadius: 4 }}>
            <Typography variant="h3" align="center" sx={{ py: 4 }}>
              What we do?
            </Typography>
          </Box>
          <Box sx={{ mx: 5 }}>
            <Typography variant={isMobile ? "h5" : "h4"} align="center">
              We help organizations build efficient and scalable AI products/features/solutions at a lightning fast speed.
            </Typography>
          </Box>
        </Box>
        {isMobile ? <Box flexGrow={1} sx={{ p: 4 }} display="flex" flexDirection="column" alignItems="center" gap={3}>
          <Box display="flex" flexDirection="column">
            <Box flexGrow={1} sx={{ backgroundColor: "#eeff41ff", p: 3 }} display="flex" flexDirection="column" justifyContent="space-evenly" gap={2}>
              <Typography variant="h5">
                AI solutions are hard to build
              </Typography>
              <Typography variant="h5">
                …don’t believe us?
              </Typography>
              <Typography variant="h5">
                …let me get Devin, to explain it to you.
              </Typography>
            </Box>
          </Box>
          <img src={LLMImage} alt="Devin" width={350} />
        </Box> : <Box flexGrow={1} sx={{ p: 4 }} display="flex" flexDirection={"row"}>
          <Box display="flex" flexDirection="column" flexBasis={"50%"}>
            <Box flexGrow={1} sx={{ backgroundColor: "#eeff41ff", p: 3 }} display="flex" flexDirection="column" justifyContent="space-evenly">
              <Typography variant="h4">
                AI solutions are hard to build
              </Typography>
              <Typography variant="h4">
                …don’t believe us?
              </Typography>
              <Typography variant="h4">
                …let me get Devin, to explain it to you.
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flexBasis={"50%"}>
            <img src={LLMImage} alt="Devin" />
          </Box>
        </Box>}
      </Box>
      <Box display="flex" flexDirection="column" sx={{ my: isMobile ? 5 : 10 }}>
        <Box display="flex" flexDirection="column" gap={5} alignItems="left" sx={{ mx: isMobile ? 2 : 18 }}>
          <Box sx={{ backgroundColor: "#eeff41ff", borderRadius: 4 }}>
            <Typography variant={isMobile ? "h4" : "h3"} align="center" sx={{ py: 4 }}>
              Challenges Devin Faces
            </Typography>
          </Box>
          <Box sx={{ mx: isMobile ? 2 : 5 }}>
            <List sx={{ listStyleType: 'disc', fontSize: isMobile ? 16 : 28, mx: isMobile ? 2 : 5 }}>
              <ListItem sx={{ display: 'list-item' }}>Finding the right dataset to solve his problem.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Annotating and labelling the data according to standards.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Developing models and analysing them for mistakes.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>Productionizing the entire AI development pipeline.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>These are just a few hurdles in a long list of AI development pain points.
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#eeff41ff", borderRadius: 4, mx: isMobile ? 2 : 18, my: isMobile ? 5 : 10  }} id="goals">
        <Typography variant="h3" align="center" sx={{ py: 4 }}>
          Goals
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" sx={{ mx: isMobile ? 2 : 44, my: isMobile ? 20 : 10 }} gap={5}>
        <Box flexBasis={"50%"} height={500} display="flex" flexDirection="column" justifyContent="center" gap={3} textAlign="center">
          <Typography sx={{ backgroundColor: "#eeff41ff", py: 3 }} variant={isMobile ? "h4" : "h2"}>
            You have to be right
          </Typography>
          <Typography sx={{ py: 3 }} variant={isMobile ? "h4" : "h2"} color={"#595959"}>
            You have to be quick
          </Typography>
        </Box>
        <Box flexBasis={"50%"} sx={{ backgroundColor: "#eeeeeeff", px: isMobile ? 1 : 5 }} height={500} display="flex" flexDirection="column" justifyContent="center" gap={3} textAlign="center">
          <Typography sx={{ py: 3 }} variant="h5">
            AI Products differ from traditional Software Products.
          </Typography>
          <Typography sx={{ py: 3 }} variant="h6" color={"#595959"} fontStyle="italic">
            People never really got to using Bard but, people are still playing Cyberpunk 2077
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" sx={{ mx: isMobile ? 2 : 44, my: isMobile ? 5 : 10 }} gap={isMobile ? 1 : 5}>
        <Box flexBasis={"50%"} display="flex" flexDirection="column" gap={3} textAlign="left">
          <Typography sx={{ backgroundColor: "#eeff41ff", p: 3 }} variant="h4">
            AI Products
          </Typography>
          <List sx={{ listStyleType: 'disc', fontSize: isMobile ? 12 : 18, px: isMobile ? 1 : 5, py: 3, backgroundColor: "#eeeeeeff" }}>
            <ListItem sx={{ display: 'list-item' }}>Deliver finished, accurate goods only.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>Development can’t really be granulated.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>VERY HIGH cost of development.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>Lack of openly available community knowledge.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>Development is hard, re-development is harder.
            </ListItem>
          </List>
        </Box>
        <Box flexBasis={"50%"} display="flex" flexDirection="column" justifyContent="center" gap={3}>
          <Typography sx={{ backgroundColor: "#eeff41ff", p: 3 }} variant="h4">
            Software Products
          </Typography>
          <List sx={{ listStyleType: 'disc', fontSize: isMobile ? 14 : 18, px: isMobile ? 1 : 5, py: 3, backgroundColor: "#eeeeeeff" }}>
            <ListItem sx={{ display: 'list-item' }}>Okay to launch with a few (or many) bugs.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>Development can be extremely granular.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>Cost of development is relatively low.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>Community knowledge and open source libraries are widely available.
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>Development is hard, re-development is easier.
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ my: isMobile ? 5 : 10 }}>
        <Box display="flex" flexDirection="column" gap={5} alignItems="left" sx={{ mx: isMobile ? 2 : 18 }}>
          <Box sx={{ backgroundColor: "#eeff41ff", borderRadius: 4 }}>
            <Typography variant={isMobile ? "h4" : "h3"} align="center" sx={{ py: 4 }}>
              How can abhiyant.ai help?
            </Typography>
          </Box>
          <Box sx={{ mx: 3 }}>
            <Typography align="left" variant={isMobile ? "h6" : "h5"}>
              The team at Abhiyant has been involved in development of multiple, in production, AI products across different fields of AI such as Computer Vision, Natural Language Processing, etc. These projects have spanned from domain of Healthcare to Nutrition to Law.
            </Typography>
          </Box>
          <Box sx={{ mx: 3 }}>
            <Typography align="center" variant={isMobile ? "h6" : "h5"} fontStyle="italic" fontFamily="monospace">
              We know what it takes to build AI products.<br /> We want to help you get there too.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ my: isMobile ? 5 : 10 }} id="ideas">
        <Box display="flex" flexDirection="column" gap={5} alignItems={isMobile ? "center" : "left"} sx={{ mx: isMobile ? 2 : 18 }}>
          <Box sx={{ backgroundColor: "#eeff41ff", borderRadius: 4 }}>
            <Typography variant={isMobile ? "h4" : "h3"} align="center" sx={{ py: 4 }}>
              Consultant In The Loop
            </Typography>
          </Box>
          {isMobile ? <Box display="flex" flexDirection="row" justifyContent="center">
            <img src={CInLoopImage} alt="Devin" width={350} />
          </Box> :
            <Box display="flex" flexDirection="row" justifyContent="center">
              <img src={CInLoopImage} alt="Devin" />
            </Box>}
          <Box sx={{ mx: 5 }}>
            <List sx={{ listStyleType: 'disc', fontSize: isMobile ? 14 : 18, px: isMobile ? 1 : 5}}>
              <ListItem sx={{ display: 'list-item' }}>Clients own less tech and get more done.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Clients have to manage less people.</ListItem>
              <ListItem sx={{ display: 'list-item' }}>Clients can scale up and down easily.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>Clients get reliable systems to bootstrap their projects.
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ my: isMobile ? 5 : 10 }} id="contact">
        <Box display="flex" flexDirection="column" gap={5} alignItems="left" sx={{ mx: isMobile ? 2 : 18 }}>
          <Box sx={{ backgroundColor: "#eeff41ff", borderRadius: 4 }}>
            <Typography variant="h3" align="center" sx={{ py: 4 }}>
              Contact Us
            </Typography>
          </Box>
          Coming soon...
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" sx={{ my: isMobile ? 5 : 10 }} id="about_us">
        <Box display="flex" flexDirection="column" gap={5} alignItems="left" sx={{ mx: isMobile ? 2 : 18 }}>
          <Box sx={{ backgroundColor: "#eeff41ff", borderRadius: 4 }}>
            <Typography variant="h3" align="center" sx={{ py: 4 }}>
              About Us
            </Typography>
          </Box>
          Coming soon...
        </Box>
      </Box>
    </Box >
  );
}

export default App;
